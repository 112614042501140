import styled from 'styled-components';

const ButtonStyle = styled.div`
	--defaultButtonPadding: var(--sp5x) var(--sp4x);
	--backButtonTextMarginLeft: var(--sp2x);
	--frameButtonWidth: var(--sp14x);
	--frameButtonHeight: var(--sp13x);
	--frameButtonIconSizes: var(--sp4x);
	--frameFullRadiusWidthHeight: var(--sp13x);
	--withIconButtonPadding: var(--sp2-5x) var(--sp6x);

	--sizeButtonFloorW: var(--sp7x);
	--sizeButtonFloorH: var(--sp7x);
	--arrowSizeFloor: var(--sp1-5x);
	--radiusFloor: var(--sp1x);

	/* //! Button animation LCR */
	@keyframes leftCenter {
		from {
			clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
		}
		to {
			clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
		}
	}
	@keyframes centerRight {
		from {
			clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
		}
		to {
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
		}
	}

	.inner-text {
		cursor: pointer;
		z-index: 1;
		position: relative;
	}

	&.default {
		border-radius: var(--sp1x);
		overflow: hidden;
		height: 100%;

		button,
		a {
			display: flex;
			position: relative;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: var(--defaultButtonPadding);
			width: 100%;
			height: 100%;
			color: var(--black1000);

			.layer {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: var(--sp1x);
				pointer-events: none;
				clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 2;
				will-change: clip-path;

				&.hover {
					animation: leftCenter var(--trTime) ease-out forwards;
					padding: var(--defaultButtonPadding);
				}

				&.leave {
					animation: centerRight var(--trTime) ease-out forwards;
					padding: var(--defaultButtonPadding);
				}
			}
		}

		&.with-icon {
			button,
			a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: var(--withIconButtonPadding);

				.button-icon {
					z-index: 1;

					&::before {
						color: var(--white);
					}
				}
			}
			.layer {
				padding: var(--withIconButtonPadding);
				justify-content: space-between;

				&.hover {
					padding: var(--withIconButtonPadding);
				}

				&.leave {
					padding: var(--withIconButtonPadding);
				}
			}
		}

		&.type-white {
			background-color: var(--white);

			/* .inner-text {
				background-image: linear-gradient(to right, var(--black1000), var(--black1000) 50%, var(--white) 50%);
				background-size: 200%;
				background-position: 0% center;
				-webkit-text-fill-color: transparent;
				background-clip: text;
			} */

			.layer {
				background-color: var(--green800);
			}

			&.disable {
				pointer-events: none;
				opacity: 0.6;
			}
		}

		&.type-red {
			background-color: var(--red1000);

			.inner-text {
				color: var(--white);
			}

			.layer {
				background-color: var(--red900);
			}

			&.disable {
				background-color: var(--disable);
				pointer-events: none;
			}
		}

		&.type-green {
			background-color: var(--green1000);

			.inner-text {
				color: var(--white);
			}

			.layer {
				background-color: var(--green800);
			}

			&.disable {
				background-color: var(--disable);
				pointer-events: none;
			}
		}

		@media (hover: hover) {
			&:hover {
				&::before {
					--hoverPosition: 100%;
				}
			}
		}
		&.disable {
			button {
				background-color: var(--disable);
				.inner-text {
					color: var(--black500);
				}
				.layer {
					transform: translate(-110%) !important;
				}
			}
		}
	}

	&.back {
		a,
		button {
			display: flex;
			align-items: center;
			cursor: pointer;

			.back-icon {
				transform: rotate(90deg);

				&::before {
					/* transition: var(--trTime); */
				}
			}

			.inner-text {
				color: var(--black1000);
				margin-left: var(--backButtonTextMarginLeft);
			}
		}

		&.disable {
			pointer-events: none;
			.back-icon {
				&::before {
					color: var(--black500);
				}
			}

			.inner-text {
				color: var(--black500);
			}
		}

		@media (hover: hover) {
			&:hover {
				.back-icon {
					&::before {
						color: var(--green800);
					}
				}

				.inner-text {
					color: var(--green800);
				}
			}
		}
	}

	&.floor-button {
		--frameButtonWidth: var(--sizeButtonFloorW);
		--frameButtonHeight: var(--sizeButtonFloorH);
		--frameButtonIconSizes: var(--arrowSizeFloor);

		border-radius: var(--radiusFloor) !important;
	}

	&.frame {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--frameButtonWidth);
		height: var(--frameButtonHeight);
		background-color: var(--white);
		border-radius: var(--sp1x);
		transition: var(--trTime);
		cursor: pointer;
		overflow: hidden;

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate(-100%);
			background-color: var(--green800);
			border-radius: var(--sp1x);
			pointer-events: none;
		}

		button,
		a {
			.arrow-icon {
				display: block;
				transform: rotate(90deg);
				font-size: var(--frameButtonIconSizes);
				cursor: pointer;
				color: var(--black1000);

				&::before {
					transition: var(--trTime);
				}
			}
		}

		@media (hover: hover) {
			&:hover {
				button,
				a {
					.arrow-icon {
						&::before {
							color: var(--white);
						}
					}
				}
			}
		}

		&.disable {
			pointer-events: none;
			background-color: var(--disable);

			button,
			a {
				.arrow-icon {
					&::before {
						color: var(--black500);
					}
				}
			}
		}

		&.full-radius {
			width: var(--frameFullRadiusWidthHeight);
			height: var(--frameFullRadiusWidthHeight);
			border-radius: 50%;
			&.disable {
				background-color: var(--disable);
			}
			.layer {
				border-radius: 50%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--defaultButtonPadding: var(--sp4x) var(--sp3x);
		--backButtonTextMarginLeft: var(--sp2x);
		--frameButtonWidth: var(--sp13x);
		--frameButtonHeight: var(--sp12x);
		--frameButtonIconSizes: var(--sp3x);
		--frameFullRadiusWidthHeight: var(--sp12x);
		--withIconButtonPadding: var(--sp2x) var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--defaultButtonPadding: var(--sp3x) var(--sp2x);
		--backButtonTextMarginLeft: var(--sp2x);
		--frameButtonWidth: var(--sp12x);
		--frameButtonHeight: var(--sp11x);
		--frameButtonIconSizes: var(--sp3x);
		--frameFullRadiusWidthHeight: var(--sp11x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp3x);

		--sizeButtonFloorW: var(--sp6x);
		--sizeButtonFloorH: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--defaultButtonPadding: var(--sp3x) var(--sp2x);
		--backButtonTextMarginLeft: var(--sp2x);
		--frameButtonWidth: var(--sp12x);
		--frameButtonHeight: var(--sp11x);
		--frameButtonIconSizes: var(--sp3x);
		--frameFullRadiusWidthHeight: var(--sp11x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp2x);

		--sizeButtonFloorW: var(--sp6x);
		--sizeButtonFloorH: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--defaultButtonPadding: var(--sp2-5x) var(--sp2x);
		--backButtonTextMarginLeft: var(--sp1-5x);
		--frameButtonWidth: var(--sp11x);
		--frameButtonHeight: var(--sp10x);
		--frameButtonIconSizes: var(--sp2x);
		--frameFullRadiusWidthHeight: var(--sp10x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp2x);

		--sizeButtonFloorW: var(--sp6x);
		--sizeButtonFloorH: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--defaultButtonPadding: var(--sp2-5x) var(--sp1-5x);
		--backButtonTextMarginLeft: var(--sp1-5x);
		--frameButtonWidth: var(--sp9x);
		--frameButtonHeight: var(--sp8x);
		--frameButtonIconSizes: var(--sp2x);
		--frameFullRadiusWidthHeight: var(--sp8x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp2x);

		--sizeButtonFloorW: var(--sp5x);
		--sizeButtonFloorH: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--defaultButtonPadding: var(--sp2-5x) var(--sp2x);
		--backButtonTextMarginLeft: var(--sp1-5x);
		--frameButtonWidth: var(--sp9x);
		--frameButtonHeight: var(--sp8x);
		--frameButtonIconSizes: var(--sp2x);
		--frameFullRadiusWidthHeight: var(--sp8x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp2x);

		--sizeButtonFloorW: var(--sp4x);
		--sizeButtonFloorH: var(--sp4x);
		--arrowSizeFloor: var(--sp1-5x);
		--radiusFloor: var(--sp0-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--defaultButtonPadding: var(--sp2x) var(--sp1-5x);
		--backButtonTextMarginLeft: var(--sp1-5x);
		--frameButtonWidth: var(--sp8x);
		--frameButtonHeight: var(--sp7x);
		--frameButtonIconSizes: var(--sp2x);
		--frameFullRadiusWidthHeight: var(--sp7x);
		--withIconButtonPadding: var(--sp1-5x) var(--sp2x);

		--sizeButtonFloorW: var(--sp4x);
		--sizeButtonFloorH: var(--sp4x);
		--arrowSizeFloor: var(--sp1-5x);
		--radiusFloor: var(--sp0-5x);
	}
`;

export default ButtonStyle;
