import { forwardRef, useCallback, useMemo, useRef } from 'react';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Component's
import Icon from '@/components/common/Icon';
import Text from '@/components/common/Text';
import CustomLink from '@/components/common/CustomLink';

//* Style
import ButtonStyle from './style';
import gsap from 'gsap/all';
import { withUIContext } from '@/context';
import { useEffect } from 'react';

const Button = forwardRef(({ btnType, text, isTouch, icon = { className: '', name: 'arrow' }, isDisabled = false, className = '', onClick, children, url, customLinkProps, translate, target, type = 'button', colorType = 'white', isFullRadius, iconName, disabled = false, external = false }, ref) => {
	//! Refs
	const layerRef = useRef();
	const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);
	const customProps = useMemo(() => (url ? { ...customLinkProps, url, target } : { type }), [url, external, type, disabled]);

	let button;

	//! Button Animation Text
	function mouseEnter() {
		const box = layerRef.current;
		box.classList.remove('leave');
		box.classList.add('hover');
	}

	function mouseLeave() {
		const box = layerRef.current;
		box.classList.remove('hover');
		box.classList.add('leave');
	}

	//! Alternate Button Animation
	const mouseEnterAlternate = useCallback(() => {
		gsap.fromTo(layerRef.current, { x: '-100%' }, { x: '0%', duration: 0.5, ease: 'power3.easeOut' });
	}, []);

	const mouseLeaveAlternate = useCallback(() => {
		gsap.fromTo(layerRef.current, { x: '0%' }, { x: '100%', duration: 0.5, ease: 'power3.easeOut' }).then(() => gsap.to(layerRef.current, { x: '-100%', duration: 0 }));
	}, []);

	switch (btnType) {
		case 'frame':
			button = (
				<ButtonStyle
					className={`${btnType} ${className} ${isDisabled ? 'disable' : ''} ${isFullRadius ? 'full-radius' : ''}`}
					onClick={onClick}
					onMouseEnter={isTouch ? null : mouseEnterAlternate}
					onMouseLeave={isTouch ? null : mouseLeaveAlternate}>
					<Component {...customProps}>
						<div
							className='layer'
							ref={layerRef}
						/>
						<Icon
							className={`arrow-icon ${icon.className}`}
							name={icon.name}
						/>
					</Component>
				</ButtonStyle>
			);
			break;

		case 'back':
			button = (
				<ButtonStyle
					className={`${btnType} ${className} ${isDisabled ? 'disable' : ''}`}
					onClick={onClick}>
					<Component {...customProps}>
						<Icon
							className={`back-icon ${icon.className}`}
							name={icon.name}
						/>
						<Text className={'p p1 font-montserrat-medium inner-text'}>{children || translate(text)}</Text>
					</Component>
				</ButtonStyle>
			);
			break;

		default:
			button = (
				<ButtonStyle
					className={`default ${iconName ? 'with-icon' : ''} ${className} ${isDisabled ? 'disable' : ''} type-${colorType}`}
					onClick={onClick}
					onMouseEnter={isTouch ? null : mouseEnter}
					onMouseLeave={isTouch ? null : mouseLeave}>
					<Component {...customProps}>
						<div
							className='layer h6 font-montserrat-medium'
							ref={layerRef}>
							{/* {children || translate(text)} */}
							<Text className={'h6 font-montserrat-medium inner-text'}>{children || translate(text)}</Text>
							{iconName && (
								<Icon
									className={'h3 button-icon'}
									name={iconName}
								/>
							)}
						</div>
						<Text className={'h6 font-montserrat-medium inner-text'}>{children || translate(text)}</Text>
						{iconName && (
							<Icon
								className={'h3 button-icon'}
								name={iconName}
							/>
						)}
					</Component>
				</ButtonStyle>
			);
			break;
	}
	return button;
});

export default withUIContext(withLanguageContext(Button, ['translate']), ['isTouch']);
