import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';

const Resize = ({ setResize, refCont, children }) => {
	//! Routes
	const pathname = usePathname();

	//! Ref
	const resizeTimeoutRef = useRef(null);

	//! Resize
	useEffect(() => {
		let bodyElement;
		if (refCont) {
			bodyElement = refCont.current;
		} else {
			bodyElement = document.querySelector('body');
		}

		const documentResize = new ResizeObserver((entries) => {
			if (resizeTimeoutRef.current) {
				clearTimeout(resizeTimeoutRef.current);
			}
			resizeTimeoutRef.current = setTimeout(() => {
				setResize(entries[0].contentRect.height);
			}, 300);
		});

		documentResize.observe(bodyElement);

		return () => {
			documentResize.disconnect();
		};
	}, [pathname]);
	return children;
};

export default Resize;
